import React from "react"
import Pagination from "react-bootstrap/Pagination"
import PropTypes from "prop-types"
import Link from "./link"

// Move constants to top of file or separate config
const ITEMS_TO_SHOW = 5
const PREV_LABEL = "‹"
const NEXT_LABEL = "›"

// Unified component for all pagination items
const createPaginationItem = ({ key, label, to, active, disabled }) => (
  <li
    key={key || `news-${label}`}
    className={`page-item ${disabled ? "disabled" : ""} ${
      active ? "active" : ""
    }`}
  >
    <Link to={to} className="page-link">
      {label}
      {(label === PREV_LABEL || label === NEXT_LABEL) && (
        <span className="sr-only">
          {label === PREV_LABEL ? "Previous" : "Next"}
        </span>
      )}
    </Link>
  </li>
)

const NewsPagination = props => {
  const { noOfPages, activePage } = props

  let items = []

  // Previous button
  const prevPage = activePage - 1
  items.push(
    createPaginationItem({
      key: "prev",
      label: PREV_LABEL,
      disabled: prevPage === 0,
      to: prevPage === 1 ? "/news" : `/news/${prevPage}`,
    })
  )

  // Calculate the range of pages to show
  let startPage, endPage
  if (noOfPages <= ITEMS_TO_SHOW) {
    // If total pages is less than items to show, display all pages
    startPage = 1
    endPage = noOfPages
  } else {
    // If we're near the start
    if (activePage <= Math.ceil(ITEMS_TO_SHOW / 2)) {
      startPage = 1
      endPage = ITEMS_TO_SHOW
    }
    // If we're near the end
    else if (activePage >= noOfPages - Math.floor(ITEMS_TO_SHOW / 2)) {
      startPage = noOfPages - ITEMS_TO_SHOW + 1
      endPage = noOfPages
    }
    // If we're in the middle
    else {
      startPage = activePage - Math.floor(ITEMS_TO_SHOW / 2)
      endPage = startPage + ITEMS_TO_SHOW - 1
    }
  }

  // First page if not in range
  if (startPage > 1) {
    items.push(
      createPaginationItem({
        label: "1",
        active: activePage === 1,
        to: "/news",
      })
    )
    if (startPage > 2) {
      // Only show ellipsis if there's actually a gap
      if (startPage > 3) {
        items.push(<Pagination.Ellipsis key="ellipsis-1" disabled />)
      } else {
        // If there's no gap, just show page 2
        items.push(
          createPaginationItem({
            label: "2",
            active: activePage === 2,
            to: "/news/2",
          })
        )
      }
    }
  }

  // Main number buttons
  for (let i = startPage; i <= endPage; i++) {
    items.push(
      createPaginationItem({
        label: String(i),
        active: i === activePage,
        to: i === 1 ? "/news" : `/news/${i}`,
      })
    )
  }

  // Last page if not in range
  if (endPage < noOfPages) {
    if (endPage < noOfPages - 1) {
      // Only show ellipsis if there's actually a gap
      if (endPage < noOfPages - 2) {
        items.push(<Pagination.Ellipsis key="ellipsis-2" disabled />)
      } else {
        // If there's no gap, just show the second-to-last page
        items.push(
          createPaginationItem({
            label: String(noOfPages - 1),
            active: activePage === noOfPages - 1,
            to: `/news/${noOfPages - 1}`,
          })
        )
      }
    }
    items.push(
      createPaginationItem({
        label: String(noOfPages),
        active: activePage === noOfPages,
        to: `/news/${noOfPages}`,
      })
    )
  }

  // Next button
  const nextPage = activePage + 1
  items.push(
    createPaginationItem({
      key: "next",
      label: NEXT_LABEL,
      disabled: nextPage > noOfPages,
      to: `/news/${nextPage}`,
    })
  )

  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  )
}

NewsPagination.propTypes = {
  noOfPages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
}

export default NewsPagination
