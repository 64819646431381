import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { graphql } from "gatsby"
import NewsPostPreview from "../components/news-post-preview"
import PillsNavigation from "../components/pills-navigation"
import NewsPagination from "../components/news-pagination"
import LinkBack from "../components/link-back"

const NewsList = ({ location, data, pageContext }) => {
  const { numPages, currentPage } = pageContext
  const { title: newsTitle } = data.newsPage
  const { title: veranstaltungenTitle } = data.veranstaltungenPage
  return (
    <Layout>
      <Seo title={newsTitle} />
      <section style={{ paddingTop: "2rem" }}>
        <Container style={{ maxWidth: "720px" }}>
          <p>
            <LinkBack to="/" label={"Homepage"} />
          </p>
          <Row className="mb-4">
            <Col>
              <PillsNavigation
                className="news-nav"
                style={{ left: "-18px" }}
                location={location}
                items={[
                  { name: newsTitle, href: "/news" },
                  { name: veranstaltungenTitle, href: "/veranstaltungen" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {data.allNewsPost.edges.map(({ node }) => (
                <NewsPostPreview post={node} key={node.id} className="mb-5" />
              ))}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="d-flex justify-content-center">
                <NewsPagination noOfPages={numPages} activePage={currentPage} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query NewsListQuery($skip: Int!, $limit: Int!) {
    veranstaltungenPage: sanityPage(_id: { eq: "veranstaltungen" }) {
      id
      title
    }
    newsPage: sanityPage(_id: { eq: "news" }) {
      id
      title
    }
    allNewsPost(
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          isNewsV1
          publishedAt(formatString: "DD MMMM YYYY", locale: "de")
          slug {
            current
          }
          parent {
            ... on MarkdownRemark {
              excerpt
            }
            ... on SanityPost {
              id
              body {
                children {
                  text
                  _type
                  marks
                  _key
                }
                _type
              }
            }
          }
        }
      }
    }
  }
`

export default NewsList
